import React, { useEffect, useState } from 'react'
import ButtonWithoutBorder from '../../dashboard-custom-components/components/Button/ButtonWithoutBorder/ButtonWithoutBorder'
import LargeModal from '../../dashboard-custom-components/components/Modal/SmallModal/LargeModal'
import AddDataButton from '../../dashboard-custom-components/components/Button/AddDataButton/AddDataButton'
import './modalEventCreate.sass'
import Cookies from 'js-cookie'; 
import { BASE } from '../../basedata/constants';
import DateTimePicker from './datapiker'
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner'

interface ModalCreateEventProps {
    show: boolean
    onHide: () => void
}

const ModalCreateEvent: React.FC<ModalCreateEventProps> = ({ show, onHide }) => {
    const [eventName, setEventName] = useState('')
    const [eventDate, setEventDate] = useState<Date | null>(null)
    const [eventCode, setEventCode] = useState('')
    const [loading, setLoading] = useState(false);

    const resetForm = () => {
        setEventName('')
        setEventDate(null)
        setEventCode('');
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onHide()
        resetForm();
    };

    const handleAddDataClick = async () => {
        setLoading(true);
        const companyId = Cookies.get('company'); 

        if (!companyId) {
            console.error('Company ID not found in cookies');
            setLoading(false);
            return;
        }

        const requestBody = {
            eventName: eventName,
            startDateTime: eventDate,
            companyId: companyId,
            eventCode: eventCode
        };

        try {
            const response = await fetch(`${BASE.EVENT.url}${BASE.EVENT.info}`, { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
            
            const result = await response.json();

            if (response.ok) {
                window.location.reload(); 
            } else {
                console.error("Error adding data:", result.message || 'Unknown error');
            }
        } catch (error) {
            console.error("Error adding data:", error);
        } finally {
            setLoading(false);
            onHide();
            resetForm();
        }
    }

    const isFormValid = () => {
        const today = new Date();
        const isNameValid = eventName.trim() !== '';
        const isCodeValid = eventCode.trim() !== '';
        const isDateValid = eventDate && eventDate >= today; 

        return isNameValid && isCodeValid && isDateValid;
    };

    return (
        <LargeModal show={show} onHide={() => { onHide(); resetForm(); }} title="Incluir Evento">
            {loading && (
                <div className="loading-spinner-container">
                    <LoadingSpinner />
                </div>
            )}
            <div className="container-modal-include-event">
                <form onSubmit={handleSubmit} className="custom-form">
                    <div className="form-group form-group-fist">
                        <label className="form-label">Nome do Evento</label>
                        <input
                            type="text"
                            value={eventName}
                            onChange={(e) => setEventName(e.target.value)}
                            maxLength={20} 
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="form-label">Data e hora do evento</label>
                        <DateTimePicker
                            selectedDate={eventDate}
                            onChangeDate={setEventDate}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="form-label">Código do Evento</label>
                        <input
                            type="text"
                            value={eventCode}
                            onChange={(e) => setEventCode(e.target.value)}
                            maxLength={5} 
                            className="form-control"
                        />
                    </div>

                    <div className='container-button'>
                        <div className='container-modal-edit-event-button'>
                            <AddDataButton
                                handleOnClick={handleAddDataClick}
                                label="INCLUIR"
                                disabled={!isFormValid()} 
                            />
                        </div>
                    </div>
                </form>
            </div>
        </LargeModal>
    )
}

export default ModalCreateEvent






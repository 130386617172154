import React, { useState, useEffect } from 'react';
import ButtonWithoutBorder from '../../dashboard-custom-components/components/Button/ButtonWithoutBorder/ButtonWithoutBorder';
import LargeModal from '../../dashboard-custom-components/components/Modal/SmallModal/LargeModal';
import AddDataButton from '../../dashboard-custom-components/components/Button/AddDataButton/AddDataButton';
import LoadingSpinner from '../../dashboard-custom-components/components/LoadingSpinner/LoadingSpinner';
import './modalIncludeCabin.sass';
import { BASE } from '../../basedata/constants';
import Cookies from 'js-cookie'; 
import DropdownSelect from '../../dashboard-custom-components/components/DropdownSelect/DropdownSelect';

interface ModalIncludeCabinProps {
    show: boolean;
    onHide: () => void;
}

const ModalIncludeCabin: React.FC<ModalIncludeCabinProps> = ({ show, onHide }) => {
    const [cabinNumber, setCabinNumber] = useState('');
    const [cabinName, setCabinName] = useState('');
    const [seatsNumber, setSeatsNumber] = useState('');
    const [sector, setSector] = useState('');
    const [loading, setLoading] = useState(false);
    const [sections, setSections] = useState<string[]>([]); 

    useEffect(() => {
        const fetchSections = async () => {
            try {
                const companyId = Cookies.get('company'); 

                if (!companyId) {
                    console.error('Company ID not found in cookies');
                    return;
                }

                const response = await fetch(`${BASE.EVENT.url}${BASE.EVENT.list_distributions}/${companyId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                const result = await response.json();

                if (response.ok) {
                    const sections = result.distribution.map((item: any) => item.section);
                    setSections(sections);
                } else {
                    console.error("Error fetching sections:", result.message || 'Unknown error');
                }
            } catch (error) {
                console.error("Error fetching sections:", error);
            }
        };

        fetchSections();
    }, []);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log({
            cabinNumber,
            cabinName,
            seatsNumber,
            sector
        });
        onHide();
    };        

    const handleAddDataClick = async () => {
        setLoading(true);
        const companyId = Cookies.get('company'); 

        if (!companyId) {
            console.error('Company ID not found in cookies');
            setLoading(false);
            return;
        }

        const requestBody = {
            companyId,
            section: sector,
            seats: seatsNumber,
            cabinId: cabinNumber,
            name: cabinName
        };

        try {
            const response = await fetch(`${BASE.USER.url}${BASE.USER.supplier}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
            
            const result = await response.json();

            if (response.ok) {
                console.log("Data added successfully:", result);
                window.location.reload(); 
            } else {
                console.error("Error adding data:", result.message || 'Unknown error');
            }
        } catch (error) {
            console.error("Error adding data:", error);
        } finally {
            setLoading(false);
            onHide();
        }
    };

    const isFormValid = () => {
        const isNumberValid = /^\d{1,5}$/.test(cabinNumber); 
        const isSeatsValid = /^\d{1,4}$/.test(seatsNumber) && Number(seatsNumber) > 0 && Number(seatsNumber) <= 9999; // Number between 1 and 9999
        return isNumberValid && isSeatsValid && sector !== '';
    };

    return (
        <LargeModal show={show} onHide={onHide} title="Incluir Camarote">
            {loading && (
                <div className="loading-spinner-container">
                    <LoadingSpinner />
                </div>
            )}
            <div className="container-modal-include-cabin">
                <form onSubmit={handleSubmit} className="custom-form">
                    <div className="form-group form-group-first">
                        <label className="form-label">Número do Camarote</label>
                        <input
                            type="text"
                            value={cabinNumber}
                            onChange={(e) => setCabinNumber(e.target.value)}
                            required
                            maxLength={5} 
                            pattern="\d*" 
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="form-label">Nome do Camarote 
                            <span className='form-label-detail'> (opcional)</span> 
                        </label>
                        <input
                            type="text"
                            value={cabinName}
                            onChange={(e) => setCabinName(e.target.value)}
                            maxLength={20} 
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label className="form-label">Quantidade de Assentos</label>
                        <input
                            type="number"
                            value={seatsNumber}
                            onChange={(e) => setSeatsNumber(e.target.value)}
                            required
                            min="1" 
                            max="9999" 
                            title=''
                            className="form-control"
                        />
                    </div>

                    <div className="form-group form-group-last">
                        <label className="form-label">Setor</label>
                        <DropdownSelect 
                            className="form-control-2"
                            items={sections.map((section) => ({ id: section, name: section }))}
                            selectedId={sector}
                            onSelect={setSector}
                            labelKey="name"
                        />
                    </div>

                    <div className='container-button'>
                        <div className='container-modal-add-data-button'>
                            <AddDataButton
                                handleOnClick={handleAddDataClick}
                                label="INCLUIR" 
                                disabled={loading || !isFormValid()} 
                            />
                        </div>
                    </div>
                </form>
            </div>
        </LargeModal>
    );
};

export default ModalIncludeCabin;




